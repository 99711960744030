const { ethers } = require("ethers");
export const CHAIN_ID = {
  MAINNET: 1,
  ROPSTEN: 3,
  RINKEBY: 4,
  GOERLI: 5,
  KOVAN: 42,
  BSC_MAINNET: 56,
  BSC_TESTNET: 97,
  POLYGON: 137,
};

export const SUPPORTED_CHAIN = [CHAIN_ID.MAINNET, CHAIN_ID.BSC_MAINNET, CHAIN_ID.POLYGON, CHAIN_ID.RINKEBY, CHAIN_ID.BSC_TESTNET];

export const CHAIN_CONFIG = {
  [CHAIN_ID.MAINNET]: {
    chainId: ethers.utils.hexValue(1),
    chainName: "Ethereum",
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    blockExplorerUrls: ["https://etherscan.io"],
  },
  [CHAIN_ID.ROPSTEN]: {
    chainId: ethers.utils.hexValue(3),
    chainName: "Ropsten (Test)",
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
  },
  [CHAIN_ID.RINKEBY]: {
    chainId: ethers.utils.hexValue(4),
    chainName: "Rinkeby (Test)",
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    blockExplorerUrls: ["https://rinkeby.etherscan.io"],
  },
  [CHAIN_ID.GOERLI]: {
    chainId: ethers.utils.hexValue(5),
    chainName: "Goerli (Test)",
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
  },
  [CHAIN_ID.KOVAN]: {
    chainId: ethers.utils.hexValue(42),
    chainName: "Kovan (Test)",
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
  },
  [CHAIN_ID.BSC_MAINNET]: {
    chainId: ethers.utils.hexValue(56),
    chainName: "BSC",
    nativeCurrency: {
      name: "BNB",
      symbol: "BNB",
      decimals: 18,
    },
    rpcUrls: ["https://bsc-dataseed.binance.org/"],
    blockExplorerUrls: ["https://bscscan.com"],
  },
  [CHAIN_ID.BSC_TESTNET]: {
    chainId: ethers.utils.hexValue(97),
    chainName: "BSC (Test)",
    nativeCurrency: {
      name: "BNB",
      symbol: "BNB",
      decimals: 18,
    },
    rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545/"],
    blockExplorerUrls: ["https://testnet.bscscan.com"],
  },
  [CHAIN_ID.POLYGON]: {
    chainId: ethers.utils.hexValue(137),
    chainName: "Polygon",
    nativeCurrency: {
      name: "MATIC",
      symbol: "MATIC",
      decimals: 18,
    },
    rpcUrls: ["https://polygon-rpc.com/"],
    blockExplorerUrls: ["https://polygonscan.com/"],
  },
};
