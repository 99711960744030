const { ethers } = require("ethers");
const { sleep } = require("./utils.js");
import { CHAIN_CONFIG, SUPPORTED_CHAIN } from "@/constants/ChainConfig";

let provider = undefined;
if (window.ethereum) {
  provider = new ethers.providers.Web3Provider(window.ethereum, "any");
  provider.on("network", (newNetwork, oldNetwork) => {
    if (oldNetwork) {
      window.location.reload();
    }
  });
}
export const providerInstance = provider;

export async function switchNetwork(chainId) {
  try {
    await window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: ethers.utils.hexValue(chainId) }], // chainId must be in hexadecimal numbers
    });
  } catch (err) {
    console.log(err);
    if (err.code != 4902) {
      throw err;
    }
    if (SUPPORTED_CHAIN.includes(chainId)) {
      try {
        await window.ethereum.request({
          method: "wallet_addEthereumChain",
          params: [CHAIN_CONFIG[chainId]],
        });
      } catch (err) {
        console.log(err);
        throw err;
      }
    }
  }
}
