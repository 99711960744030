import { createApp } from 'vue'
import App from './App.vue'

import {createRouter, createWebHashHistory} from 'vue-router'
import 'ant-design-vue/dist/antd.css';

import HomePage from './components/HomePage.vue'
import HomeWhitelist from './components/HomeWhitelist.vue'
import AutoMint from './components/AutoMint.vue'

import { 
    Button, 
    Modal, 
    Select, 
    Input, 
    InputNumber, 
    Radio, 
    Layout, 
    Divider,
    Space,
    Spin,
    Result,
    Tabs,
    Row,
    Col,
    Typography,
    Tooltip,
    Table,
    List,
    message 
} from 'ant-design-vue';


const routes = [
    { path: '/', component: HomePage },
    { path: '/whitelist', component: HomeWhitelist },
    { path: '/automint', component: AutoMint }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes, // short for `routes: routes`
})

const app = createApp(App)
app.use(router)
app.use(Layout)
app.use(Button)
app.use(Modal)
app.use(Select)
app.use(Input)
app.use(InputNumber)
app.use(Radio)
app.use(Divider)
app.use(Space)
app.use(Spin)
app.use(Result)
app.use(Tabs)
app.use(Row)
app.use(Col)
app.use(Typography)
app.use(Tooltip)
app.use(Table)
app.use(List)
app.config.globalProperties.$message = message;

app.mount('#app')
