<template>
  <h1>
    This auto mint page is constructed by <a href="https://discord.com/invite/crepto" target="_blank">Crepto Bot</a>
  </h1>
  <p>contract: {{ contract }}</p>
  <p>input: {{ input }}</p>
  <p>NFT: {{ nftName }}</p>
  <p v-if="slug">
    <a :href="openseaURL" target="_blank"> Check OpenSea Link</a>
  </p>
  <div v-if="tokenId">
    <img :src="nftImageUrl" alt="NFT preview" loading="lazy" style="width: 400px; height: auto" />
  </div>
  <div>-------------------------------------------------------------------------------------</div>
  <div v-if="address">
    <p>Wallet: {{ address }} balance: {{ balance / 10 ** 18 }} ETH</p>
    <div style="color: red">
      <p>
        Cautions: This free mint parameters come from other free mint transactions, so this mint might fail and you may
        loss gas fee. Take your own risk!
      </p>
    </div>
    <a-button type="primary" v-on:click="mint" :loading="isLoading">Free Mint</a-button>
    <div v-if="txHash">
      Tx hash: <a :href="txHashEtherscan" target="_blank">{{ txHash }}</a>
    </div>
    <div v-if="isSuccess">
      Mint Success! Check your NFT at <a href="https://opensea.io/account" target="_blank">OpenSea</a>
    </div>
  </div>
  <div v-else>
    <a-button v-on:click="connect">Connect Your Wallet</a-button>
  </div>
</template>

<script>
import { providerInstance } from "../utils/metamask";
import { ethers } from "ethers";
import { CHAIN_ID } from "@/constants/ChainConfig";
import axios from "axios";

const SUPPORTED_CHAINS = [CHAIN_ID.MAINNET, CHAIN_ID.RINKEBY];
const ABI = [
  {
    inputs: [
      { internalType: "address", name: "to", type: "address" },
      { internalType: "uint256", name: "tokenId", type: "uint256" },
    ],
    name: "approve",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "owner", type: "address" }],
    name: "balanceOf",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "tokenId", type: "uint256" }],
    name: "getApproved",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "owner", type: "address" },
      { internalType: "address", name: "operator", type: "address" },
    ],
    name: "isApprovedForAll",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "name",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "tokenId", type: "uint256" }],
    name: "ownerOf",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "from", type: "address" },
      { internalType: "address", name: "to", type: "address" },
      { internalType: "uint256", name: "tokenId", type: "uint256" },
    ],
    name: "safeTransferFrom",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "from", type: "address" },
      { internalType: "address", name: "to", type: "address" },
      { internalType: "uint256", name: "tokenId", type: "uint256" },
      { internalType: "bytes", name: "_data", type: "bytes" },
    ],
    name: "safeTransferFrom",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "operator", type: "address" },
      { internalType: "bool", name: "approved", type: "bool" },
    ],
    name: "setApprovalForAll",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "symbol",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "totalSupply",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "from", type: "address" },
      { internalType: "address", name: "to", type: "address" },
      { internalType: "uint256", name: "tokenId", type: "uint256" },
    ],
    name: "transferFrom",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "tokenId", type: "uint256" }],
    name: "tokenURI",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    stateMutability: "view",
    type: "function",
  },
];

export default {
  data: function () {
    return {
      contract: undefined,
      input: undefined,
      slug: undefined,
      tokenId: undefined,
      nftImageUrl: undefined,
      nftName: undefined,
      address: undefined,
      balance: undefined,
      isSuccess: false,
      isLoading: false,
      txHash: undefined,
    };
  },
  created: async function () {
    console.log("created");
    this.contract = this.$route.query.contract;
    this.input = this.$route.query.input;
    this.slug = this.$route.query.slug;
    this.tokenId = this.$route.query.tokenId;

    const contract = new ethers.Contract(this.contract, ABI, providerInstance);
    try {
      contract.name().then((res) => {
        this.nftName = res;
      });
    } catch (err) {
      console.log("Can't get NFT name, check your network");
    }

    if (this.tokenId) {
      try {
        const tokenURI = await contract.tokenURI(this.tokenId);
        console.log(tokenURI);
        let metadataURL = undefined;
        if (tokenURI.startsWith("ipfs")) {
          metadataURL = tokenURI.replace("ipfs://", "https://ipfs.io/ipfs/");
        } else if (tokenURI.startsWith("http")) {
          metadataURL = tokenURI;
        } else if (tokenURI.startsWith("data:application/json;base64,")) {
          const base64Str = tokenURI.split("data:application/json;base64,")[1];
          const metadataStr = Buffer.from(base64Str, "base64").toString("utf8");
          const metadata = JSON.parse(metadataStr);
          this.nftImageUrl = metadata.image;
          // console.log(metadata.image);
        }
        if (metadataURL) {
          const response = await axios.get(metadataURL);
          const image = response.data.image;
          if (image.startsWith("ipfs")) {
            this.nftImageUrl = image.replace("ipfs://", "https://ipfs.io/ipfs/");
          } else if (image.startsWith("http")) {
            this.nftImageUrl = image;
          }
        }
        console.log(this.nftImageUrl);
      } catch (err) {
        console.log("Can't get NFT preview image");
      }
    }
  },
  methods: {
    connect: async function () {
      console.log("click connect");
      await this.initWallet();
    },
    initWallet: async function () {
      if (!providerInstance) {
        this.$message.error("Please install MetaMask");
        return;
      }
      const accounts = await providerInstance.send("eth_requestAccounts", []);
      this.address = ethers.utils.getAddress(accounts[0]);
      const network = await providerInstance.getNetwork();
      if (network && SUPPORTED_CHAINS.includes(network.chainId)) {
        this.balance = await providerInstance.getBalance(this.address);
      } else {
        this.$message.error("Please connect to Ethereum Main Network");
      }
    },
    mint: async function () {
      console.log("click mint");
      const txReq = {
        data: this.input,
        from: this.address,
        to: this.contract,
      };
      this.isLoading = true;
      this.isSuccess = false;
      this.txHash = undefined;
      try {
        const tx = await providerInstance.getSigner().sendTransaction(txReq);
        console.log(tx);
        this.txHash = tx.hash;
        const response = await tx.wait();
        console.log(response);
        this.$message.success("Mint Success!");
        this.isSuccess = true;
      } catch (err) {
        this.$message.error("Mint Error!");
        console.log(err);
      }
      this.isLoading = false;
    },
  },
  computed: {
    txHashEtherscan: function () {
      return "https://etherscan.io/tx/" + this.txHash;
    },
    openseaURL: function () {
      return "https://opensea.io/collection/" + this.slug;
    },
  },
};
</script>

<style scoped></style>
