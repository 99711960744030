<template>
  <div style="margin: auto; width: 50%; text-align: center">
    <table>
      <tr>
        <th>Address</th>
        <th>Count</th>
      </tr>
      <tr v-for="item in whitelist" :key="item.address">
        <!-- <td>{{ formatAddress(item.address) }}</td> -->
        <td>{{ item.address }}</td>
        <td>{{ item.amount }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
import ListedWalletAllowed from "../constants/Whitelist.json";

export default {
  data: function () {
    return {
      whitelist: undefined,
    };
  },
  created: async function () {
    console.log("created");
    this.whitelist = ListedWalletAllowed;
  },
  methods: {
    formatAddress: function (address) {
      const prefix = address.slice(0, 8);
      const suffix = address.slice(-6);
      return prefix + "..." + suffix;
    },
  },
  computed: {},
};
</script>

<style scoped></style>
